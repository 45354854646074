.notification_wrapper{
    right: -8px;
}
.notification_wrapper{
    width: 461px;
}
.navbar-collapse{
    transition: all ease-out .7s
}
.header_items_outer {
    margin-left: 11px !important;
}
@media screen and (max-width:991px){
    .header_items_outer{
        margin-left: 0 !important;
    }

    .notification_wrapper{
        position: unset;
    }
    .notification_outer{
        margin-left: 0 !important;
    }
}
@media screen and (max-width:767px){
    .notification_wrapper{
        width: 100%;
    }
    .Sign_Out_outer{
        margin-left: 0 !important;
    }
}