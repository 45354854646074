table tbody td {
    padding: 8px;
}

table tbody tr {
    /*border-bottom: 1px solid #202020;*/
    border-bottom: 1px solid rgba(32, 32, 32, 0.47);
}

table tbody tr:last-child {
    border-bottom: unset;
}
table thead th {
    padding: 8px;
}

table thead tr {
    border-bottom: 1px solid #36824f;
}
