.login_form::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    background: #0C2919FF;
    background: linear-gradient(0deg, #0C2919FF 0%, #10723b 50%, #14974c 100%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(2px);
}

.login_form::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    background-color: rgb(0 0 0 / 40%);
}

.login_form{
    height: calc(100vh - 48PX);
}

.forgot-password-txt{
    color: #eee;
    font-size: 16px;
    line-height: 24px  ;
}

.log_in .form-control {
    backdrop-filter: blur(4.5px);
    -webkit-backdrop-filter: blur(4.5px);
    background: hsla(0, 0%, 100%, .3);
    border: 1px solid hsla(0, 0%, 100%, .18);
    color: var(--text-color);
}

.log_in .form-control::placeholder{
    color: var(--text-color);
}


.log_in {
    position: relative;
    z-index: 1;
}