@tailwind base;
@tailwind components;
@tailwind utilities;

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057 ;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom_scrollbar{
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

.breadcrumb-item+.breadcrumb-item:before {
  padding-left: 0 !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: #6c757d;
  content: "/";
}
.toggle_class:after{
  background: white;
  border: unset !important;
}
.css-1dimb5e-singleValue {
  color: white !important;
}

.css-13cymwt-control {
  background: transparent !important;
}

:focus-visible {
  outline: unset !important;
}

i.fa.cursor-pointer.absolute.show_password {
  right: 16px;
  top: 11px;
}

.bootstrap-tabs-component ul {
  margin-bottom: 0;
}

.bootstrap-tabs-component ul li button {
  color: white;
  padding: 6px 10px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.bootstrap-tabs-component ul li button.active {
  background: rgb(54 130 79 / var(--tw-bg-opacity));
}

.bootstrap-tabs-component{
  position: relative;
}

.bootstrap-tabs-component ul {
  display: flex;
}
.add_icon_outer{
  position: absolute;
  left: auto;
  top: 0;
}

.shadow-custom{
  box-shadow: 0 0 6px #0000004d;
}

.overlay {
  background: rgba(0, 0, 0, 0.7); /* Adjust the alpha value to control the dimming intensity */
}

.note-section textarea {
  width: calc(100% - 72px);
}
.invoice_wrapper .col-span-9 {
  width: calc(100% - 277px);
}

.droppable-container {
  max-width: 100%; /* Ensure the droppable container doesn't overflow */
  overflow-x: hidden; /* Hide overflow to prevent horizontal movement */
}


.model_overflow ::-webkit-scrollbar {
  width: 3px;
}

.model_overflow ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.model_overflow ::-webkit-scrollbar-thumb {
  background: #888;
}

.model_overflow ::-webkit-scrollbar-thumb:hover {
  background: #555;
}