
#default-sidebar{
   transition: all .5s ease;
}

aside#default-sidebar\ \ transition-all\ duration-500\ ease-in-out {
   padding-top: 42px !important;
}


li.dropDownList::before {
   background: #fff;
   bottom: 0;
   content: "";
   height: 42px;
   left: 0;
   position: absolute;
   top: 2px;
   width: 2px;
}

li.dropDownList {
   padding: 0 22px;
   line-height: 41px;
}

li.dropDownList::after {
   background: #fff;
   content: "";
   height: 2px;
   left: 2px;
   position: absolute;
   top: 21px;
   width: 9px;
}

li.dropDownList:last-child::before{
   height: 29px;
   top: -6px;
}
.sidebar_list_Wrapper li{
   margin-top: 0 !important;
}

.tooltip::before {
   content: "";
   position: absolute;
   top: 17px;
   left: 0%;
   right: auto;
   margin-top: -5px;
   border-color: transparent #e5e9f0 transparent transparent;
   border-bottom: 5px solid transparent;
   border-right: 8px solid #82b22e ;
   border-top: 5px solid transparent;
}


.tooltip {
   display: none;
   flex-direction: column; /* Ensures child items are in a column */
}

.group:hover .tooltip {
   display: flex; /* Display tooltip on hover */
}

.tooltip a,
.tooltip div {
   padding: 4px 0;
}