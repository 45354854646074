.order_tab_outer:first-child h3::after {
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: -16px;
    top: 0;
    width: 0;


}

.active_tab h3::after {
    border-left: 16px solid #519163;
}

.inactive_tab h3::after {
    border-left: 16px solid #656565;
}

.order_tab_outer:nth-child(2) h3::before {
    border-bottom: 19px solid transparent;
    border-top: 19px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
    border-left: 14px solid #353535 !important

}

.order_tab_outer:nth-child(2) h3::after {
    border-bottom: 20px solid transparent;
    border-top: 20px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: -16px;
    top: 0;
    width: 0;
}

.order_tab_outer:nth-child(3) h3::before {
    border-bottom: 19px solid transparent;
    border-left: 14px solid #353535 !important;
    border-top: 19px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
}

.inactive_tab {
    background-color: #656565
}

.order_finish_tab_Wrapper {
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.tab_container {
    border-bottom: 1px solid #2e2e2e
}

.order_table_Wrapper {
    background-color: #323232;
}

.order_table_Wrapper thead th {
    text-align: left;
}

.order_doc_outer {
    border-left: 7px solid #656565;
}

.order_history_wrapper {
    border: 1px solid #3e3d3d;
}


.relative.note-section.invoice_wrapper {
    left: unset !important;
    right: unset !important;
    height: unset !important;
    top: unset !important;
}